<template>
  <div class="index">
    <Header />
    <div class="box-1">
      <div class="box-1-container">
        <img
          alt=""
          src="../assets/icons/index-trust_pilot.svg"
          class="trust_pilot"
        />
        <div class="box-1-title">
        <h1>
          {{ $t("index_protect_your_online_life") }}
        </h1>
        </div>
        <div class="box-1-subtitle">
        <h2>
          {{ $t("index_enjoy_life_with_fastest_vpn") }}
        </h2>  
        </div>
        <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`">
          <button
            type="button"
            class="
              box-1-btn
              btn-size-format-1 btn-radius-format-1 btn-color-1 btn-cursor
            "
          >
            <span>Get ShellVPN</span>
          </button>
        </router-link>
      </div>
      <img
        alt="enjoy the fastest vpn"
        src="../assets/image/index_box_1_image.svg"
        class="box-1-image"
      />
    </div>
    <div class="box-2">
      <img
        alt="vpnmentor"
        src="../assets/image/index_vpn_mentor.svg"
        class="vpn_mentor"
      />
      <img
        alt="producthunt"
        src="../assets/image/index_product_hunt.svg"
        class="product_hunt"
      />
    </div>
    <div class="box-3">
      <div class="box-3-content">
        <div class="box-3-title">
        <h3>
          {{ $t("index_easy_to_use") }}
        </h3>  
        </div>
        <div class="box-3-subtitle">
          {{ $t("index_easy_to_use_subtitle") }}
        </div>
        <div class="box-3-platform">
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=0'}`"
          >
            <div class="platform-box">
              <img
                class="platform-icon-android"
                src="../assets/icons/index-android.svg"
              />
              <span class="platform-name"> Android </span>
            </div>
          </router-link>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=1'}`"
          >
            <div class="platform-box">
              <img
                class="platform-icon-windows"
                src="../assets/icons/index-windows.svg"
              />
              <span class="platform-name"> Windows </span>
            </div>
          </router-link>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=2'}`"
          >
            <div class="platform-box">
              <img
                class="platform-icon-mac"
                src="../assets/icons/index-mac.svg"
              />
              <span class="platform-name"> Mac </span>
            </div>
          </router-link>
          <div
            class="platform-box"
            @mouseleave="showOrHideDownloadIosTip(false)"
            @mouseover="showOrHideDownloadIosTip(true)"
          >
            <img
              class="platform-icon-ios"
              src="../assets/icons/index-ios.svg"
            />
            <span class="platform-name"> iOS </span>
            <div class="download-ios-tip" v-show="isShowDownloadIosTip">
              <span>coming soon...</span>
            </div>
          </div>
        </div>
        <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`">
          <button
            type="button"
            class="
              box-3-btn
              btn-size-format-1 btn-radius-format-1 btn-color-1 btn-cursor
            "
          >
            <span>Get ShellVPN</span>
          </button>
        </router-link>
      </div>
      <div class="box-3-position">
        <img
          alt="best vpn for phone"
          src="../assets/image/index_box_3_left.svg"
          class="box-3-left1"
        />
        <img
          alt="fast vpn"
          src="../assets/image/index_box_3_left2.svg"
          class="box-3-left2"
        />
        <img
          alt="best vpn for laptop"
          src="../assets/image/index_box_3_right.svg"
          class="box-3-right"
        />
      </div>
    </div>
    <div class="box-4">
      <div class="box-4-content-row" id="features_anchor">
        <div class="box-4-content-row-text">
          <div class="box-4-content-row-title">
            {{ $t("index_unlock") }}
          </div>
          <div class="box-4-content-row-subtitle">
            {{ $t("index_unlock_subtitle") }}
            {{ $t("index_unlock_subtitle2") }}
          </div>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
          >
            <button
              type="button"
              class="
                box-4-btn
                btn-size-format-1 btn-radius-format-1 btn-color-1 btn-cursor
              "
            >
              <span>Get ShellVPN</span>
            </button>
          </router-link>
        </div>
        <img
          alt="unlock worldwide content with shell vpn"
          src="../assets/image/index_box_4_unlock.svg"
          class="box-4-image-unlock"
        />
      </div>
      <div class="box-4-content-row">
        <div class="box-4-content-row-text">
          <div class="box-4-content-row-title">
            {{ $t("index_one_tap") }}
          </div>
          <div class="box-4-content-row-subtitle">
            {{ $t("index_one_tap_subtitle") }}
            {{ $t("index_one_tap_subtitle2") }}
          </div>
          <div class="box-4-privacy-row">
            <div class="box-4-privacy">
              <div class="box-4-privacy-left">
                <img alt="zero log" src="../assets/image/check.png" />
              </div>
              <div class="box-4-privacy-text">
                {{ $t("index_one_tap_point1") }}
              </div>
            </div>
            <div class="box-4-privacy">
              <div class="box-4-privacy-left">
                <img alt="advanced vpn" src="../assets/image/check.png" />
              </div>
              <div class="box-4-privacy-text">
                {{ $t("index_one_tap_point2") }}
              </div>
            </div>
          </div>
          <div class="box-4-privacy-row">
            <div class="box-4-privacy">
              <div class="box-4-privacy-left">
                <img alt="unlimited vpn" src="../assets/image/check.png" />
              </div>
              <div class="box-4-privacy-text">
                {{ $t("index_one_tap_point3") }}
              </div>
            </div>
          </div>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
          >
            <button
              type="button"
              class="
                box-4-btn
                btn-size-format-1 btn-radius-format-1 btn-color-1 btn-cursor
              "
            >
              <span>Get ShellVPN</span>
            </button>
          </router-link>
        </div>
        <img
          alt="one tap to use vpn"
          src="../assets/image/index_box_4_tap.svg"
          class="box-4-image-tap"
        />
      </div>
      <div class="box-4-content-row">
        <div class="box-4-content-row-text">
          <div class="box-4-content-row-title">
            {{ $t("index_fastest_technology") }}
          </div>
          <div class="box-4-content-row-subtitle">
            {{ $t("index_fastest_technology_subtitle") }}
          </div>
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
          >
            <button
              type="button"
              class="
                box-4-btn
                btn-size-format-1 btn-radius-format-1 btn-color-1 btn-cursor
              "
            >
              <span>Get ShellVPN</span>
            </button>
          </router-link>
        </div>
        <img
          alt="fastest vpn technology"
          src="../assets/image/index_box_4_fastest.svg"
          class="box-4-image-fastest"
        />
      </div>
    </div>
    <div class="box-5" id="server_anchor">
      <div class="box-5-title">
        {{ $t("index_huge_global") }}
      </div>
      <div class="box-5-subtitle">
        {{ $t("index_huge_global_subtitle") }}
      </div>
      <div class="box-5-map">
        <Map />
      </div>
    </div>
    <div class="box-6">
      <div class="box-6-content-box">
        <div class="box-6-content-item">
          <div class="box-6-content-box-title-icon">
            <div class="box-6-content-box-title">
              <span>{{ $t("index_customer_support") }}</span>
            </div>
            <img
              alt="shellvpn support"
              src="../assets/image/index_box_6_support.svg"
              class="box-6-content-box-icon-support"
            />
          </div>
          <div class="box-6-content-box-subtitle">
            {{ $t("index_customer_support_subtitle") }}
          </div>
        </div>
        <div class="box-6-content-item">
          <div class="box-6-content-box-title-icon">
            <div class="box-6-content-box-title">
              <span>{{ $t("index_money_back_guarantee") }}</span>
            </div>
            <img
              alt="shellvpn guarantee"
              src="../assets/image/index_box_6_guarantee.svg"
              class="box-6-content-box-icon-guarantee"
            />
          </div>
          <div class="box-6-content-box-subtitle">
            {{ $t("index_money_back_guarantee_subtitle") }}
          </div>
        </div>
      </div>
      <button
        type="button"
        class="
          box-6-content-btn
          btn-size-format-1 btn-radius-format-1 btn-color-3 btn-cursor
        "
        @click="startContact"
      >
        <span>{{ $t("index_contact_us") }}</span>
      </button>
    </div>
    <div class="box-7">
      <div class="box-7-content">
        <img
          class="box-7-content-icon"
          alt="users says"
          src="../assets/image/index_box_7_what_about_people_saying.svg"
        />
        <div class="box-7-content-title">
          {{ $t("index_what_are_people_saying_about") }}
        </div>
      </div>
      <div class="box-7-content-comment">
        <Comment />
      </div>
    </div>
    <div class="box-8">
      <div class="box-8-title">
        {{ $t("index_choose_a_plan") }}
      </div>
      <div class="box-8-plan-box">
        <PaymentPackage />
      </div>
    </div>
    <DownloadComponents />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Map from "../components/Map.vue";
import Comment from "../components/Comment.vue";
import DownloadComponents from "../components/DownloadComponents.vue";
import PaymentPackage from "../components/PaymentPackage.vue";

export default {
  name: "Index",
  components: {
    Header,
    Footer,
    Map,
    Comment,
    DownloadComponents,
    PaymentPackage,
  },
  data() {
    return {
      isShowDownloadIosTip: false,
    };
  },
  created() {
    let anchor = this.$route.query.anchor;
    if (anchor !== null && anchor !== undefined) {
      this.goAnchor(anchor);
    }
  },
  methods: {
    showOrHideDownloadIosTip(value) {
      this.isShowDownloadIosTip = value;
    },
    goAnchor(selector) {
      this.$nextTick(() => {
        document.documentElement.scrollTop = this.$el.querySelector(
          "#" + selector
        ).offsetTop;
      });
    },
    startContact() {
      // eslint-disable-next-line no-undef
      Tawk_API.maximize();
    },
  },
  watch: {
    $route: {
      handler() {
        let anchor = this.$route.query.anchor;
        if (anchor !== null && anchor !== undefined) {
          this.goAnchor(anchor);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.index {
  display: flex;
  flex-direction: column;
}

.box-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
}

.box-1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.trust_pilot {
  margin-top: 64px;
  margin-left: 26px;
  align-self: flex-start;
}

.box-1-title h1{
  font-size: 70px;
  font-weight: bold;
  line-height: 80px;
  color: #3a4d50;
  text-align: center;
}

.box-1-subtitle h2{
  margin-top: 28px;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: #565267;
  text-align: center;
}

.box-1-btn {
  margin-top: 27px;
}

.box-1-image {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
}

.box-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.vpn_mentor {
  margin: 20px;
  max-width: 100%;
  height: auto;
}

.product_hunt {
  margin: 20px;
  max-width: 100%;
  height: auto;
}

.box-3 {
  background: radial-gradient(circle, #384e44 0%, #0b100e 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-3-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}

.box-3-title h3{
  margin-top: 115px;
  max-width: 654px;
  height: auto;
  font-size: 48px;
  font-weight: 500;
  line-height: 54px;
  color: #ffffff;
  text-align: center;
}

.box-3-subtitle {
  max-width: 481px;
  height: auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  color: #ffffff;
  text-align: center;
}

.box-3-platform {
  margin-top: 85px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
}

.platform-box {
  margin: 20px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
}

.platform-icon-android {
  width: 31px;
  height: 36px;
}

.platform-icon-windows {
  width: 36px;
  height: 33px;
}

.platform-icon-mac {
  width: 38px;
  height: 33px;
}

.platform-icon-ios {
  width: 22px;
  height: 38px;
}

.platform-name {
  margin-top: 10px;
  height: 18px;
  font-size: 14px;
  font-weight: 200;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
}

.box-3-btn {
  margin-top: 19px;
  margin-bottom: 106px;
}

.box-3-btn span {
  font-weight: 500;
}

.box-3-position {
  position: absolute;
  width: 100%;
  max-width: 2634px;
  height: 957px;
  overflow: hidden;
  pointer-events: none;
}

.box-3-left1 {
  position: absolute;
  margin-top: 162px;
  left: 0;
}

@media screen and (max-width: 2434px) {
  .box-3-left1 {
    left: -415px;
  }
}

@media screen and (max-width: 1600px) {
  .box-3-left1 {
    left: -615px;
  }
}

@media screen and (max-width: 1150px) {
  .box-3-left1 {
    left: -815px;
  }
}

@media screen and (max-width: 750px) {
  .box-3-left1 {
    display: none;
  }
}

.box-3-left2 {
  position: absolute;
  margin-top: 152px;
  left: 640px;
}

@media screen and (max-width: 2434px) {
  .box-3-left2 {
    left: 225px;
  }
}

@media screen and (max-width: 1600px) {
  .box-3-left2 {
    left: 25px;
  }
}

@media screen and (max-width: 750px) {
  .box-3-left2 {
    display: none;
  }
}

@media screen and (max-width: 1150px) {
  .box-3-left2 {
    left: -175px;
  }
}

.box-3-right {
  position: absolute;
  margin-top: 189px;
  right: 0;
}

@media screen and (max-width: 2434px) {
  .box-3-right {
    right: -299px;
  }
}

@media screen and (max-width: 1600px) {
  .box-3-right {
    right: -499px;
  }
}

@media screen and (max-width: 1150px) {
  .box-3-right {
    right: -699px;
  }
}

@media screen and (max-width: 750px) {
  .box-3-right {
    display: none;
  }
}

.box-4 {
  padding: 253px 16px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-4-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.box-4-content-row-text {
  margin: 25px 0;
  max-width: 486px;
  margin-top: 17px;
}

.box-4-content-row-title {
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  color: #2d3b3d;
}

.box-4-content-row-subtitle {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
}

.box-4-btn {
  margin-top: 30px;
}

.box-4-content-row:nth-child(2) {
  margin-top: 134px;
  flex-direction: row-reverse;
}

.box-4-content-row:nth-child(3) {
  margin-top: 134px;
}

.box-4-image-unlock {
  margin: 25px 20px;
  max-width: 100%;
  height: auto;
}

.box-4-image-tap {
  margin: 25px 20px;
  max-width: 100%;
  height: auto;
}

.box-4-image-fastest {
  margin: 25px 20px;
  max-width: 100%;
  height: auto;
}

.box-4-privacy-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.box-4-privacy {
  display: flex;
  align-items: center;
}

.box-4-privacy:nth-child(1) {
  margin-right: 34px;
}

.box-4-privacy-left {
  width: 22px;
  height: 22px;
  background: #ddf7ea;
  border-radius: 50%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4ebe86;
}

.box-4-privacy-left img {
  width: 22px;
  height: 22px;
}

.box-4-privacy-text {
  line-height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #1f1f1f;
}

.box-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  padding: 0 16px;
}

.box-5-title {
  margin-top: 60px;
  max-width: 610px;
  font-size: 48px;
  font-weight: 500;
  line-height: 57px;
  color: #3a4d50;
  text-align: center;
}

.box-5-subtitle {
  margin-top: 11px;
  max-width: 843px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #666666;
  text-align: center;
}

.box-5-map {
  margin: 42px 0 10px;
}

.box-6 {
  padding: 204px 0 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/image/index_box_6_bg.svg");
  background-size: cover;
  background-position: center top;
}

.box-6-content-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.box-6-content-item {
  display: flex;
  flex-direction: column;
  max-width: 408px;
  margin: 50px 30px;
}

.box-6-content-box-title-icon {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.box-6-content-box-title {
  max-width: 298px;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
  color: #ffffff;
}

.box-6-content-box-icon-support {
  width: 77px;
  height: 113px;
}

.box-6-content-box-subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #e5e5e5;
  margin-top: 7px;
}

.box-6-content-box-icon-guarantee {
  width: 110px;
  height: 110px;
}

.box-6-content-btn {
  margin-top: 11px;
}

.box-7 {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  padding: 0 16px 138px;
}

.box-7-content {
  margin-top: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.box-7-content-icon {
  max-width: 100%;
  height: auto;
}

.box-7-content-title {
  max-width: 651px;
  font-size: 48px;
  font-weight: 500;
  line-height: 58px;
  color: #3a4d50;
  text-align: center;
}

.box-7-content-comment {
  margin-top: 37px;
}

.box-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  background-color: #1d2d25;
}

.box-8-title {
  margin-top: 138px;
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
  color: #ffffff;
  text-align: center;
}

.box-8-plan-box {
  margin-top: 103px;
  margin-bottom: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-ios-tip {
  width: 88px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: -5px;
  margin-left: 130px;
  color: #1d1d1d;
  text-align: center;
}
</style>
