<template>
  <div class="downloadComponents">
    <img alt="" src="../assets/image/download.svg" class="download-icon" />
    <div class="download-title">
      {{ $t("download_download_now_for_free") }}
    </div>
    <div class="download-subtitle">
      {{ $t("download_download_now_for_free_subtitle") }}
    </div>
    <div class="platform-box">
      <router-link
        :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=1'}`"
      >
        <div class="platform btn-cursor">
          <div class="platform-text">
            <span>{{ $t("download_download_for_windows") }}</span>
          </div>
          <img
            alt="vpn for windows"
            src="../assets/image/download_components_windows.svg"
            class="platform-icon platform-icon-windows"
          />
        </div>
      </router-link>
      <router-link
        :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=0'}`"
      >
        <div class="platform btn-cursor">
          <div class="platform-text">
            <span>{{ $t("download_download_for_android") }}</span>
          </div>
          <img
            alt="vpn for android"
            src="../assets/image/download_components_android.svg"
            class="platform-icon platform-icon-android"
          />
        </div>
      </router-link>
      <router-link
        :to="`${'/' + this.GLOBAL.currentLanguage + '/apps?platform=2'}`"
      >
        <div class="platform btn-cursor">
          <div class="platform-text">
            <span>{{ $t("download_download_for_macos") }}</span>
          </div>
          <img
            alt="vpn for mac"
            src="../assets/image/download_components_mac.svg"
            class="platform-icon platform-icon-mac"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadComponents",
};
</script>

<style scoped>
.downloadComponents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fafafa;
  padding: 0 16px 47px;
}

.download-icon {
  margin-top: -60px;
}

.download-title {
  margin-top: 39px;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
  color: #3a4d50;
  text-align: center;
}

.download-subtitle {
  margin-top: 10px;
  max-width: 547px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #666666;
  text-align: center;
}

.platform-box {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.platform {
  width: 200px;
  height: 50px;
  margin: 30px 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: #fff;
}

.platform-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ffffff;
  border: 1px solid #4ebe86;
  border-radius: 5px;
}

.platform-text span {
  width: 147px;
  font-size: 14px;
  font-weight: 500;
  color: #4ebe86;
}

.platform-icon {
  color: #4ebe86;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.25s;
  opacity: 0;
}

.platform-icon-windows {
  width: 63px;
  height: 59px;
}

.platform-icon-android {
  width: 70px;
  height: 70px;
}

.platform-icon-mac {
  width: 73px;
  height: 68px;
}

.platform:hover .platform-icon {
  transform: translateY(-50%);
  opacity: 1;
}
</style>
