<template>
  <div class="Map">
    <img
      id="map_bg"
      class="Map-bg"
      alt=""
      src="../assets/image/map_index.svg"
    />
    <div id="icon_container" class="icon-container">
      <img alt="canada vpn" src="../assets/image/map_1.png" class="map-icon" />
      <img alt="us vpn" src="../assets/image/map_2.png" class="map-icon" />
      <img alt="brazil vpn" src="../assets/image/map_3.png" class="map-icon" />
      <img alt="uk vpn" src="../assets/image/map_4.png" class="map-icon" />
      <img alt="france vpn" src="../assets/image/map_5.png" class="map-icon" />
      <img
        alt="switzerland vpn"
        src="../assets/image/map_6.png"
        class="map-icon"
      />
      <img alt="de vpn" src="../assets/image/map_7.png" class="map-icon" />
      <img alt="italy vpn" src="../assets/image/map_8.png" class="map-icon" />
      <img alt="finland vpn" src="../assets/image/map_9.png" class="map-icon" />
      <img alt="tr vpn" src="../assets/image/map_10.png" class="map-icon" />
      <img alt="india vpn" src="../assets/image/map_11.png" class="map-icon" />
      <img alt="ru vpn" src="../assets/image/map_12.png" class="map-icon" />
      <img alt="sg vpn" src="../assets/image/map_13.png" class="map-icon" />
      <img alt="china vpn" src="../assets/image/map_14.png" class="map-icon" />
      <img
        alt="indonesia vpn"
        src="../assets/image/map_15.png"
        class="map-icon"
      />
      <img alt="vpn" src="../assets/image/map_16.png" class="map-icon" />
      <img alt="kr vpn" src="../assets/image/map_17.png" class="map-icon" />
      <img alt="au vpn" src="../assets/image/map_18.png" class="map-icon" />
      <img alt="jp vpn" src="../assets/image/map_19.png" class="map-icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Map",
  mounted() {
    this.setIconsScale();
    window.addEventListener("resize", this.setIconsScale);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setIconsScale);
  },
  methods: {
    setIconsScale() {
      const mapBg = document.getElementById("map_bg");
      const iconContainer = document.getElementById("icon_container");
      setTimeout(() => {
        iconContainer.style.transform = `scale(${mapBg.width / 971})`;
      }, 50);
      
    },
  },
};
</script>

<style scoped>
.Map {
  display: flex;
  flex-direction: column;
}

.Map-bg {
  width: 971px;
  max-width: 100%;
  height: auto;
}

.icon-container {
  position: absolute;
}

.map-icon {
  position: absolute;
  transition: all 0.5s;
  cursor: pointer;
}

.map-icon:hover {
  transform: scale(1.25);
}

.map-icon:nth-child(1) {
  left: 164px;
  top: 120px;
  width: 62px;
  height: 62px;
}

.map-icon:nth-child(2) {
  left: 146px;
  top: 198px;
  width: 64px;
  height: 64px;
}

.map-icon:nth-child(3) {
  left: 302px;
  top: 365px;
  width: 56px;
  height: 56px;
}

.map-icon:nth-child(4) {
  left: 419px;
  top: 151px;
  width: 48px;
  height: 48px;
}

.map-icon:nth-child(5) {
  left: 426px;
  top: 188px;
  width: 44px;
  height: 44px;
}

.map-icon:nth-child(6) {
  left: 444px;
  top: 209px;
  width: 41px;
  height: 41px;
}

.map-icon:nth-child(7) {
  left: 454px;
  top: 178px;
  width: 43px;
  height: 43px;
}

.map-icon:nth-child(8) {
  left: 471px;
  top: 216px;
  width: 43px;
  height: 43px;
}

.map-icon:nth-child(9) {
  left: 503px;
  top: 126px;
  width: 38px;
  height: 38px;
}

.map-icon:nth-child(10) {
  left: 519px;
  top: 223px;
  width: 48px;
  height: 48px;
}

.map-icon:nth-child(11) {
  left: 645px;
  top: 260px;
  width: 58px;
  height: 58px;
}

.map-icon:nth-child(12) {
  left: 700px;
  top: 74px;
  width: 55px;
  height: 55px;
}

.map-icon:nth-child(13) {
  left: 710px;
  top: 327px;
  width: 44px;
  height: 44px;
}

.map-icon:nth-child(14) {
  left: 734px;
  top: 283px;
  width: 55px;
  height: 55px;
}

.map-icon:nth-child(15) {
  left: 757px;
  top: 346px;
  width: 48px;
  height: 48px;
}

.map-icon:nth-child(16) {
  left: 762px;
  top: 260px;
  width: 49px;
  height: 49px;
}

.map-icon:nth-child(17) {
  left: 781px;
  top: 204px;
  width: 54px;
  height: 54px;
}

.map-icon:nth-child(18) {
  left: 798px;
  top: 404px;
  width: 58px;
  height: 58px;
}

.map-icon:nth-child(19) {
  left: 822px;
  top: 222px;
  width: 54px;
  height: 54px;
}
</style>
